(function() {
    var Sidenav;
    var __bind = function(fn, me) {
        return function() {
            return fn.apply(me, arguments);
        };
    };

    Sidenav = ( function() {
        function Sidenav(element) {
            this.target = $('.side-nav');
            this.element = element;
            this._sendEventsAfter = __bind(this._sendEventsAfter, this);
            this._sendEventsBefore = __bind(this._sendEventsBefore, this);
            this._documentClicked = __bind(this._documentClicked, this);
            this._clicked = __bind(this._clicked, this);
            this.element.on("click", this._clicked);
            $(document).on("click", this._documentClicked);
            this.popover();
            this.dropdown();
        }

        Sidenav.prototype.popover = function() {
            var options = {
                animation: 'fade',
                content: function() {
                    return $(this).find('span').html();
                },
                container: '.content',
                html: true,
                placement: 'right',
                trigger: 'hover',
                template: '<div class="popover popover-side-nav" role="tooltip"><h3 class="popover-title"></h3><div class="popover-content"></div></div>'
            };
            $('.side-nav-link a').popover(options);
            var target = this.target;
            $('.side-nav-link a').on('inserted.bs.popover', function() {
                if (parseInt(target.css('width')) > 100) {
                    $('#' + $(this).attr('aria-describedby')).addClass('hide');
                } else {
                    $('#' + $(this).attr('aria-describedby')).removeClass('hide');
                }
            });
        };

        Sidenav.prototype.dropdown = function() {
            $(".side-nav-dropdown-toggle").click(function(e) {
                e.preventDefault();
                $(this).parent('.side-nav-dropdown').toggleClass('open');
            });
        };

        Sidenav.prototype._clicked = function(e) {
            e.preventDefault();
            this._sendEventsBefore();
            this.target.toggleClass('open');
        };

        Sidenav.prototype._documentClicked = function(e) {
            var clickedEl;
            clickedEl = $(e.target);
            if (!clickedEl.hasClass('side-nav') && clickedEl.parents('.side-nav').length === 0 && !clickedEl.hasClass('toogle-side-nav') && clickedEl.parents('.toogle-side-nav').length === 0 && !clickedEl.hasClass('background-side-nav') && clickedEl.parents('.background-side-nav').length === 0) {
                if (this.target.hasClass('open')) {
                    this.target.removeClass('open');
                }
            }
        };

        Sidenav.prototype._sendEventsBefore = function() {
            if (!this.target.hasClass('open')) {
                return this.target.trigger('show.sidenav');
            } else {
                return this.target.trigger('hide.sidenav');
            }
        };

        Sidenav.prototype._sendEventsAfter = function() {
            if (!this.target.hasClass('open')) {
                return this.target.trigger('shown.sidenav');
            } else {
                return this.target.trigger('hidden.sidenav');
            }
        };

        return Sidenav;

    } )();

    $(function() {
        new Sidenav($('.toogle-side-nav'));
    });

}).call(this);
